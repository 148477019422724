var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Systematic analysis on medRxiv submissions 2020-2021")]),_c('v-card',[_c('v-card-title',[_vm._v("Options")]),_c('v-row',{staticClass:"mx-5"},[_c('v-col',[_c('div',[_c('h3',[_vm._v("General options")]),_c('v-subheader',[_vm._v("Primary terms")]),_c('multiselect',{attrs:{"options":_vm.termOptions,"multiple":true,"close-on-select":true,"placeholder":"Select primary terms of interests or leave empty to pick top count"},model:{value:(_vm.termSelect),callback:function ($$v) {_vm.termSelect=$$v},expression:"termSelect"}}),_c('v-subheader',[_vm._v("Predicate subset")]),_c('v-select',{attrs:{"items":_vm.predModeOptions,"outlined":"","dense":""},model:{value:(_vm.predModeSelect),callback:function ($$v) {_vm.predModeSelect=$$v},expression:"predModeSelect"}})],1)]),_c('v-col',[_c('div',[_c('h3',[_vm._v("Diagram options")]),_c('v-subheader',[_vm._v("Edge width calculation")]),_c('v-select',{attrs:{"items":_vm.edgeModeOptions,"outlined":"","dense":""},model:{value:(_vm.edgeModeSelect),callback:function ($$v) {_vm.edgeModeSelect=$$v},expression:"edgeModeSelect"}})],1)])],1),_c('v-btn',{staticClass:"my-5 mx-5",attrs:{"tile":"","color":"primary","disabled":_vm.btnState.disabled},on:{"click":_vm.updateData}},[_vm._v(_vm._s(_vm.btnState.label))])],1),_c('v-divider',{staticClass:"py-5"}),_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.resultsTab),callback:function ($$v) {_vm.resultsTab=$$v},expression:"resultsTab"}},[_c('v-tab',[_c('v-tooltip',{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("About")])]}}]),model:{value:(_vm.showDocsTooltip),callback:function ($$v) {_vm.showDocsTooltip=$$v},expression:"showDocsTooltip"}},[_c('span',[_vm._v("Read documentation here")]),_c('br'),_vm._v("for first time visitors")])],1),_c('v-tab-item',[_c('div',{staticClass:"py-2"}),_c('v-container',{attrs:{"fluid":""}},[_c('vue-markdown',{attrs:{"source":_vm.$store.state.docs.analysis.about,"breaks":false}})],1)],1),_c('v-tab',[_vm._v("Results")]),_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Network diagram")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","tile":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.toggleFullscreen('#vis-network-plot')}}},[_vm._v("Fullscreen")])],1),(_vm.plotData)?_c('network',{staticClass:"vis-network-plot",attrs:{"id":"vis-network-plot","nodes":_vm.plotData.nodes,"edges":_vm.plotData.edges,"options":_vm.plotOptions}}):_vm._e()],1)],1),_c('v-col',[(_vm.analysisData)?_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Results table")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tblData,"search":_vm.search,"items-per-page":20,"dense":""},scopedSlots:_vm._u([{key:"header.triple",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.ents.validTriple}},[_vm._v(_vm._s(header.text))])]}},{key:"header.triple_evidence_supporting_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.analysis.tripleEvidenceSupporting}},[_vm._v(_vm._s(header.text))])]}},{key:"header.triple_evidence_reversal_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.analysis.tripleEvidenceReversal}},[_vm._v(_vm._s(header.text))])]}},{key:"header.assoc_evidence_supporting_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.analysis.assocEvidenceSupporting}},[_vm._v(_vm._s(header.text))])]}},{key:"header.assoc_evidence_reversal_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.analysis.assocEvidenceReversal}},[_vm._v(_vm._s(header.text))])]}},{key:"header.assoc_evidence_insufficient_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.analysis.assocEvidenceInsufficient}},[_vm._v(_vm._s(header.text))])]}},{key:"header.assoc_evidence_additional_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.analysis.assocEvidenceAdditional}},[_vm._v(_vm._s(header.text))])]}},{key:"item.triple",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(item.subject_term))]),_c('br'),_vm._v("   "),_c('span',{staticClass:"font-italics"},[_vm._v(_vm._s(item.pred_term))]),_c('br'),_c('span',[_vm._v(_vm._s(item.object_term))])])])]}},{key:"item.doi_count",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.doi_count))]),_c('literature-source',{attrs:{"source-data":item.doi,"triple":item.triple}})],1)]}},{key:"item.triple_evidence_supporting_score",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.triple_evidence_supporting_disp))])]}},{key:"item.triple_evidence_reversal_score",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.triple_evidence_reversal_disp))])]}},{key:"item.assoc_evidence_supporting_score",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.assoc_evidence_supporting_disp))])]}},{key:"item.assoc_evidence_reversal_score",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.assoc_evidence_reversal_disp))])]}},{key:"item.assoc_evidence_insufficient_score",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.assoc_evidence_insufficient_disp))])]}},{key:"item.assoc_evidence_additional_score",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.assoc_evidence_additional_disp))])]}}],null,false,3206230113)})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }