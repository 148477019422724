




















import Vue from "vue";
import { PropType } from "vue";

export default Vue.extend({
  name: "AnalysisLiteratureSource",
  components: {
    //
  },
  props: {
    sourceData: {
      type: Array as PropType<
        Array<{ doi: string; title: string; context: string }>
      >,
      required: true,
    },
    triple: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showLiteratureDialog: false,
    };
  },
  computed: {
    literatureData(): Array<{ doi: string; title: string; url: string }> {
      const res = this._.chain(this.sourceData)
        .map((e) => ({
          ...e,
          url: `https://doi.org/${e.doi}`,
        }))
        .value();
      return res;
    },
  },
  methods: {
    //
  },
});
